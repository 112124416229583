import React from "react";
import { RelayEnvironment } from "./RelayEnvironment";
import { RelayEnvironmentProvider } from "react-relay";
import { ThemeProvider } from "styled-components";
import ReactDOM from "react-dom";

import "./index.css";
import "./stylesheets/site.css.scss";
import { defaultTheme } from "./theme";
import App from "./App";

import $ from "jquery";

$("#loading").show();

const rootEl = document.getElementById("root");
if (document.getElementById("root")) {
  ReactDOM.render(
    <React.StrictMode>
      <RelayEnvironmentProvider environment={RelayEnvironment}>
        <ThemeProvider theme={defaultTheme}>
          <App />
        </ThemeProvider>
      </RelayEnvironmentProvider>
    </React.StrictMode>,
    rootEl
  );
}
