export const PROJECTFIELDS_FETCH = "PROJECTFIELDS_FETCH";
export const PROJECTFIELDS_ERROR = "PROJECTFIELDS_ERROR";
export const REPORTS_FETCH = "REPORTS_FETCH";
export const REPORTS_ERROR = "REPORTS_ERROR";
export const KPIS_FETCH = "KPIS_FETCH";
export const KPIS_ERROR = "KPIS_ERROR";
export const KPI_PROPERTIES_FETCH = "KPI_PROPERTIES_FETCH";
export const KPI_PROPERTIES_POST_IN_FLIGHT = "KPI_PROPERTIES_POST_IN_FLIGHT";
export const KPI_AUTHORIZATIONS_FETCH = "KPI_AUTHORIZATIONS_FETCH";
export const KPI_AUTHORIZATIONS_ERROR = "KPI_AUTHORIZATIONS_ERROR";
export const DOCUMENTS_FETCH = "DOCUMENTS_FETCH";
export const DOCUMENTS_ERROR = "DOCUMENTS_ERROR";
export const ADD_FEATURE = "ADD_FEATURE";
export const REMOVE_FEATURE = "REMOVE_FEATURE";
export const ADD_CUMULATION_FEATURE = "ADD_CUMULATION_FEATURE";
export const REMOVE_CUMULATION_FEATURE = "REMOVE_CUMULATION_FEATURE";
export const ADD_FEATURES = "ADD_FEATURES";
export const SWITCH_MAP_LAYER = "SWITCH_MAP_LAYER";
