import React from "react";

function Error({
  text,
  action,
  actionText,
}: {
  text: string;
  action?: () => any;
  actionText?: string;
}) {
  return (
    <div className="l-row">
      <div className="l-row__container">
        <div className="l-row__single l-row__single--small">
          <div className="c-alert-wrapper">
            <div className="c-alert c-alert--error">
              {text || "Er ging iets mis, probeer het nog eens."}
            </div>
          </div>
          <div className="c-buttons-wrapper">
            {action ? (
              <button onClick={action} className="c-button c-button c-button--block c-button--l">
                {actionText || "Probeer het opnieuw"}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Error;
