import React from "react";
import ErrorText from "./Error";

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };
  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }
  render() {
    if (this.state?.hasError) {
      return <ErrorText text={"Er ging iets mis, probeer het nog eens."} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
