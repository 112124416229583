import * as PAGES from "./pages";

export const PAGE_HELP_TEXT: { [key: string]: Array<string> } = {
  [PAGES.LOGIN]: [
    "Welkom, uw collectief wenst u veel succes met deze nieuwe vorm van communiceren over uw deelname aan de uitvoering van het Agrarisch Natuur en Landschapsbeheer. Neem contact op met uw collectief als u nog geen gebruikersnaam en/of wachtwoord heeft ontvangen.",
  ],
  [PAGES.HOME]: [
    "Welkom, uw collectief wenst u veel succes met deze nieuwe vorm van communiceren over uw deelname aan de uitvoering van het Agrarisch Natuur en Landschapsbeheer. Neem contact op met uw collectief als u nog geen gebruikersnaam en/of wachtwoord heeft ontvangen.",
  ],
  [PAGES.MAP]: [
    "Deze kaart biedt een overzicht van de percelen en landschapselementen waarop u beheer heeft afgesproken met uw collectief. U kunt beheereenheden opzoeken via de kaart, of u kunt beheereenheden zoeken via de lijst met beheereenheden. Wanneer u op een beheereenheid klikt opent zich een detailscherm met meer informatie. Klikken op het icoontje van de beheereenheid links laat u de beheereenheid op de kaart zien. In het detailscherm staan ook eventuele foutmeldingen benoemd die op uw perceel kunnen liggen. Het i’tje naast de foutmelding geeft u meer informatie over wat voor actie kunt u ondernemen.",
  ],
  [PAGES.PROJECTFIELDS]: [
    "Onderstaande tabel is een overzicht van de percelen, landschapselementen en/of sloten (beheereenheden) waarop u een beheerovereenkomst heeft afgesloten met uw collectief. De tabel betreft alle beheereenheden die in het gekozen jaar actief zijn. De totale looptijd van het contract op een beheereenheid kan zich over meerdere jaren uitstrekken.",
    "- ID: als u hier op klikt gaat u naar het betreffende perceel op de kaart",
    "- Beheerpakket: dit is de beheermaatregel die u bent overeengekomen voor de betreffende beheereenheid. Als u hierop klikt dan opent er een pdf met de voorwaarden.",
    "- KI (kwaliteitsindicator): indien hier een getal staat, betekent dit dat er één of meerdere foutmeldingen zijn gevonden op de betreffende beheereenheid. Voor meer informatie kunt u zich wenden tot het collectief.",
    "- Melden: Een ‘ja’ in de kolom ’melden’ betekent dat uitgevoerd beheer voor het betreffende jaar nog gemeld moet worden. Een ‘nee’ in de kolom ‘melden’ betekent dat er geen uitgevoerd beheer gemeld hoeft te worden op deze beheereenheid of dat deze melding reeds is gedaan.",
  ],
  [PAGES.REPORTS]: [
    "Deze pagina geeft een overzicht van de meldingen van uitgevoerd beheer die door uw collectief zijn ingediend bij RVO.nl. Het betreft alleen de meldingen van het gekozen beheerjaar (linksboven in het menu).",
  ],
  [PAGES.DOCUMENTS]: [
    "Deze pagina geeft een overzicht van belangrijke documenten zoals contracten en betaalspecificaties. De documenten zijn niet verbonden aan het gekozen beheerjaar. U kunt het document openen door op de naam van het document te klikken.",
  ],
  [PAGES.MEMBER]: [
    "Welkom op mijnboerennatuur.nl. U kunt hier als deelnemer aan het ANLb (Agrarisch Natuur en Landschapsbeheer) op ieder moment uw ANLb-percelen met bijbehorende beheermaatregelen inzien. Mijnboerennatuur.nl is direct gekoppeld aan de ICT-omgeving van uw collectief. Uw gegevens zijn daarmee altijd actueel. De ICT-producten voor collectieven en deelnemers worden landelijk beheerd door BoerenNatuur; de gezamenlijke vertegenwoordiging van de 40 Nederlandse agrarische collectieven. Voor vragen over mijnboerennatuur.nl kunt u zich wenden tot uw collectief.",
  ],
  [PAGES.MEMBER_DETAILS]: [
    "Deze pagina biedt een overzicht van uw gegevens zoals deze bij het collectief bekend zijn.",
  ],
  [PAGES.PASSWORD_RESET]: [
    'Wanneer u uw wachtwoord bent vergeten kunt u gebruik maken van onderstaand formulier om uw wachtwoord te resetten. Nadat u uw gebruikersnaam heeft ingevuld en op "Reset wachtwoord" hebt geklikt, ontvangt u een mail met een link waarmee u uw wachtwoord opnieuw kunt instellen.',
  ],
  [PAGES.PASSWORD_SET]: [
    'Stel uw nieuwe wachtwoord in en bevestig deze met behulp van onderstaand formulier. Klik vervolgens op "Stel wachtwoord in" om uw nieuwe wachtwoord vast te leggen.',
  ],
  [PAGES.ACCOUNT]: [
    'Stel uw nieuwe wachtwoord in en bevestig deze met behulp van onderstaand formulier. Klik vervolgens op "Wijzig” om uw nieuwe wachtwoord vast te leggen.',
  ],
};

export const PAGES_LINKING_TO_CONTACT = [
  PAGES.HOME,
  PAGES.MAP,
  PAGES.PROJECTFIELDS,
  PAGES.REPORTS,
  PAGES.DOCUMENTS,
  PAGES.MEMBER,
  PAGES.MEMBER_DETAILS,
];
