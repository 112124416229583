export function createCanvasPattern() {
  /*
    https://gis.stackexchange.com/questions/172728/how-can-i-fill-polygon-with-strokes-in-openlayers-3
    https://github.com/openlayers/openlayers/issues/2208
    https://stackoverflow.com/questions/44982783/openlayers-4-use-canvas-pattern-for-feature-style
    http://jsfiddle.net/61b7szjn/
    https://rawgit.com/ca0v/ol3-lab/v3.20.1/rawgit.html?run=ol3-lab/labs/style-viewer&geom=point&style=%5B%7B%22circle%22:%7B%22fill%22:%7B%22pattern%22:%7B%22orientation%22:%22horizontal%22,%22color%22:%22rgba(10,20,134,1)%22,%22spacing%22:5,%22repitition%22:%22repeat%22%7D%7D,%22opacity%22:1,%22stroke%22:%7B%22color%22:%22rgba(0,255,0,1)%22,%22width%22:1%7D,%22radius%22:64%7D%7D,%7B%22image%22:%7B%22anchor%22:%5B16,48%5D,%22imgSize%22:%5B32,48%5D,%22anchorXUnits%22:%22pixels%22,%22anchorYUnits%22:%22pixels%22,%22src%22:%22http://openlayers.org/en/v3.20.1/examples/data/icon.png%22%7D%7D%5D
    */
  let cnv = document.createElement("canvas");
  let ctx = cnv.getContext("2d") as any;
  cnv.width = 3;
  cnv.height = 3;
  ctx.fillStyle = "rgb(0, 0, 0)";
  ctx.fillRect(0, 0, 1, 1);

  return ctx.createPattern(cnv, "repeat");
}

export function sortOnExternalID(a: any, b: any) {
  return a.external_id > b.external_id ? 1 : -1;
}

export function sortOnSelected(a: any, b: any) {
  return b.selected ? 1 : -1;
}

export const CANCELLED_PROJECTFIELD_COLOR = "#CC0000";
export const CANCELLED_PROJECTFIELD_TEXT = "Beheerpakket wordt niet verlengd";

/** Retrieve crsftoken from the cookies */
export function fetchCookie(): string | undefined {
  return document.cookie
    ?.split(";")
    ?.find((cookieString) => cookieString?.indexOf("csrftoken") >= 0)
    ?.split("=")[1];
}
