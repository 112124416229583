import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";

import { Maps } from "../pages/Map/components/MapLayerControl/types";

export const mapBackgroundOsm = new TileLayer({
  source: new OSM(),
});

export const mapBackgroundSummer = new TileLayer({
  source: new XYZ({
    attributions: 'Open data via <a href="https://www.pdok.nl/">pdok.nl</a>',
    projection: "EPSG:3857",
    maxZoom: 19,
    url: `https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0/2023_ortho25/EPSG:3857/{z}/{x}/{y}.jpeg?&request=GetTile&service=wmts`,
  }),
});

export const mapBackgroundWinter = new TileLayer({
  source: new XYZ({
    attributions: 'Open data via <a href="https://www.pdok.nl/">pdok.nl</a>',
    projection: "EPSG:3857",
    maxZoom: 19,
    url: `https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0/2023_orthoHR/EPSG:3857/{z}/{x}/{y}.jpeg?&request=GetTile&service=wmts`,
  }),
});

export function getMapBackgroundLayer(selectedLayer: Maps) {
  let newBackground: TileLayer<XYZ | OSM> | undefined = undefined;
  switch (selectedLayer) {
    case Maps.LUFOWINTER:
      newBackground = mapBackgroundWinter;
      break;
    case Maps.LUFOZOMER:
      newBackground = mapBackgroundSummer;
      break;
    default:
      newBackground = mapBackgroundOsm;
      break;
  }
  return newBackground;
}

export const projectFieldsVectorLayer = new VectorLayer({
  source: new VectorSource({
    features: [],
  }),
});

export const cumulationsVectorLayer = new VectorLayer({
  source: new VectorSource({
    features: [],
  }),
});

export const overlayVectorLayer = new VectorLayer({
  source: new VectorSource({
    features: [],
  }),
});
