const SCAN_LOGO_SMALL = require("../images/scan-logo-small.png");
const SCAN_LOGO_BIG = require("../images/scan-logo-big.png");
const SCAN_BACKGROUND = require("../images/scan-bg.jpg");

export const CUSTOMER_LOGO_SMALL = SCAN_LOGO_SMALL;
export const CUSTOMER_LOGO_BIG = SCAN_LOGO_BIG;
export const CUSTOMER_BG = SCAN_BACKGROUND;

export const ACTIVITY_IMG = (activity: string) =>
  require(`../images/activities/activity_${activity.replace(/\D/g, "")}.svg`);
