import { DefaultTheme } from "styled-components";

export const defaultTheme: DefaultTheme = {
  // Expand when needed with variables from: stylesheets/_settings.variables.scss
  breakpoint: {
    5: "500px",
    10: "600px",
    20: "750px",
    30: "1000px",
    40: "1250px",
    50: "1400px",
  },
};
