import { Feature, Map, View } from "ol";
import Point from "ol/geom/Point";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import { MemberKPIs } from "../types/MemberKPIs";
import IProjectField from "../types/IProjectField";
import IReport from "../types/IReport";
import {
  ADD_CUMULATION_FEATURE,
  ADD_FEATURE,
  DOCUMENTS_ERROR,
  DOCUMENTS_FETCH,
  KPIS_ERROR,
  KPIS_FETCH,
  KPI_PROPERTIES_FETCH,
  KPI_PROPERTIES_POST_IN_FLIGHT,
  PROJECTFIELDS_ERROR,
  PROJECTFIELDS_FETCH,
  REMOVE_FEATURE,
  REPORTS_ERROR,
  REPORTS_FETCH,
  SWITCH_MAP_LAYER,
} from "./action-types";
import { Maps } from "../pages/Map/components/MapLayerControl/types";
import {
  cumulationsVectorLayer,
  getMapBackgroundLayer,
  mapBackgroundOsm,
  overlayVectorLayer,
  projectFieldsVectorLayer,
} from "../constants/mapLayers";

const vectorLayers = [projectFieldsVectorLayer, cumulationsVectorLayer, overlayVectorLayer];
const initialState: {
  projectFields: Array<IProjectField> | undefined;
  projectFieldsError: boolean;
  reports: Array<IReport> | undefined;
  reportsError: boolean;
  kpis: Array<MemberKPIs> | undefined;
  kpisError: boolean;
  kpisAllowSharing: boolean;
  toggleKPIConfigurationInFlight: boolean;
  selectedLayer: Maps | undefined;
  map: Map;
} = {
  projectFields: undefined,
  projectFieldsError: false,
  reports: undefined,
  reportsError: false,
  kpis: undefined,
  kpisError: false,
  kpisAllowSharing: false,
  toggleKPIConfigurationInFlight: false,
  selectedLayer: undefined, // MapLayerControl controls the selectedLayer.
  map: new Map({
    view: new View({
      center: fromLonLat([6.578139199239601, 52.94064148818865]),
      zoom: 10,
    }),
    layers: [...vectorLayers, mapBackgroundOsm],
    controls: [],
  }),
};

const rootReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case PROJECTFIELDS_FETCH:
      return { ...state, projectFields: action.payload };
    case PROJECTFIELDS_ERROR:
      return { ...state, projectFieldsError: true };
    case REPORTS_FETCH:
      return {
        ...state,
        reports: action.payload,
      };
    case REPORTS_ERROR:
      return { ...state, reportsError: true };
    case DOCUMENTS_FETCH:
      return { ...state, documents: action.payload };
    case DOCUMENTS_ERROR:
      return { ...state, documentsError: true };
    case KPIS_FETCH:
      return { ...state, kpis: action.payload, kpisError: false };
    case KPIS_ERROR:
      return { ...state, kpisError: true };
    case KPI_PROPERTIES_POST_IN_FLIGHT:
      return { ...state, toggleKPIConfigurationInFlight: action.payload };
    case KPI_PROPERTIES_FETCH:
      return {
        ...state,
        kpisAllowSharing: action.payload,
        kpisError: false,
      };
    case ADD_FEATURE:
      (vectorLayer(state.map)?.getSource() as VectorSource)?.addFeature(action.payload);
      return Object.assign({}, state, {
        map: state.map,
      });
    case REMOVE_FEATURE:
      const validationFeatureToRemove = (vectorLayer(state.map)?.getSource() as VectorSource)
        ?.getFeatures()
        ?.find((feature: Feature) => compareFlatCoordinates(feature, action.payload));
      if (validationFeatureToRemove !== undefined) {
        (vectorLayer(state.map)?.getSource() as VectorSource)?.removeFeature(
          validationFeatureToRemove!
        );
      }
      return Object.assign({}, state, {
        map: state.map,
      });
    case ADD_CUMULATION_FEATURE:
      (cumulationsLayer(state.map)?.getSource() as VectorSource)?.addFeature(action.payload);
      return Object.assign({}, state, {
        map: state.map,
      });
    case SWITCH_MAP_LAYER:
      const newBackground = getMapBackgroundLayer(action.payload);
      state.map.setLayers([newBackground, ...vectorLayers]);
      return Object.assign({}, state, {
        selectedLayer: action.payload,
      });
    default:
      return state;
  }
};
export default rootReducer;
const vectorLayer = (map: Map) => {
  return map
    .getLayers()
    .getArray()
    .find((layer) => {
      if (layer === projectFieldsVectorLayer) {
        return layer;
      }
      return undefined;
    }) as VectorLayer<VectorSource> | undefined;
};

const cumulationsLayer = (map: Map) => {
  return map
    .getLayers()
    .getArray()
    .find((layer) => {
      if (layer === cumulationsVectorLayer) {
        return layer;
      }
      return undefined;
    }) as VectorLayer<VectorSource> | undefined;
};

const compareFlatCoordinates = (feature: any, payload: any) => {
  return (
    (feature.getGeometry() as Point).getFlatCoordinates().length ===
      (payload.getGeometry() as Point).getFlatCoordinates().length &&
    (feature.getGeometry() as Point)
      .getFlatCoordinates()
      .every(
        (value, index) => value === (payload.getGeometry() as Point).getFlatCoordinates()[index]
      )
  );
};
