export const LOGIN = "login";
export const HOME = "home";
export const ACCOUNT = "account";
export const MAP = "kaart";
export const PROJECTFIELDS = "percelen";
export const REPORTS = "meldingen";
export const DOCUMENTS = "documenten";
export const MEMBER_DETAILS = "gegevens";
export const CONTACT = "contact";
export const MEMBER = "member";
export const PASSWORD_SET = "password_set";
export const PASSWORD_RESET = "password_reset";
