import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PAGES_LINKING_TO_CONTACT, PAGE_HELP_TEXT } from "../constants/helpText";

type Props = {
  helpToggled: boolean;
  onToggleHelp: () => any;
};
export default function HelpText({ helpToggled, onToggleHelp }: Props) {
  const location = useLocation();
  const currentPage = location.pathname.split("/")[1];
  const [showMore, toggleShowMore] = useState<boolean>(false);
  const helpText = PAGE_HELP_TEXT[currentPage] as [string];

  useEffect(() => {
    return () => {
      if (helpToggled) {
        onToggleHelp();
      }
    };
  }, [helpToggled, onToggleHelp, currentPage, location.pathname]);

  return (
    <div
      className={`c-alert-wrapper ${currentPage === "kaart" ? "c-alert-wrapper--map" : ""} ${
        !helpToggled ? "c-alert-wrapper--hidden" : ""
      }`}
    >
      <div className="c-alert">
        <a className="c-alert__close c-link c-link--neutral c-link--close" onClick={onToggleHelp}>
          Sluiten
          <svg className="c-icon c-icon--size-m c-icon--inline c-navigation-secondary__icon">
            <use xlinkHref="#icon-close" />
          </svg>
        </a>
        <h3 className="c-heading c-heading--font-size-s c-heading--spacing-s">
          <svg className="c-icon c-icon--size-m">
            <use xlinkHref="#icon-chat" />
          </svg>
          Help
        </h3>
        {(helpText || ["Geen helptekst aanwezig"]).map((text, index) =>
          index === 0 ? (
            <p key={`paragraph_${index}`}>{text}</p>
          ) : (
            <p
              key={`paragraph_${index}`}
              className={`more_info ${!showMore ? "c-paragraph--hidden" : ""}`}
            >
              {text}
            </p>
          )
        )}
        <div className="c-buttons-wrapper c-buttons-wrapper--small-spacing">
          <a href="#" className="c-button" onClick={onToggleHelp}>
            Ik begrijp het
          </a>
          {(helpText || []).length > 1 ? (
            <a
              className="c-link c-link--neutral c-buttons-wrapper__copy"
              onClick={() => toggleShowMore(!showMore)}
            >
              {showMore ? "Minder informatie" : "Meer informatie"}
            </a>
          ) : null}
          {PAGES_LINKING_TO_CONTACT?.includes(currentPage) ? (
            <Link to="/contact/" className="c-account__link">
              Neem contact op
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
}
